




























import Vue, { PropType } from 'vue'
import countto from 'vue-count-to'

export default Vue.extend({
  components: {
    countto,
  },
  props: {
    msgList: Array as PropType<
      {
        icon: string
        title: string
        number: number
      }[]
    >,
  },
  computed: {
    first(): any {
      return this.msgList[0]
    },
    second(): any {
      return this.msgList[1]
    },
    third(): any {
      return this.msgList[2]
    },
    fourth(): any {
      return this.msgList[3]
    },
  },
  watch: {
    first(p, c) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      p.number !== c.number && (this.$refs.countto as any)[0].start()
    },
    second(p, c) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      p.number !== c.number && (this.$refs.countto as any)[1].start()
    },
    third(p, c) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      p.number !== c.number && (this.$refs.countto as any)[2].start()
    },
    fourth(p, c) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      p.number !== c.number && (this.$refs.countto as any)[3].start()
    },
  },
})
