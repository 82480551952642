





























































































import Vue, { PropType } from "vue";
import LatestContainer from "@/components/pc/BlockChainBrowser/LatestContainer.vue";
import { iBlockMsg } from "ycc-api/dist/cmjs/service/home/types";

export default Vue.extend({
  components: { LatestContainer },
  props: {
    latestBlocks: Array as PropType<iBlockMsg[]>,
  },
});
