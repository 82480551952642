









































import VueTypedMixins from 'vue-typed-mixins'
import ChainCard from '@/components/pc/BlockChainBrowser/ChainOverview/ChainCard.vue'
import ChainTitle from '@/components/pc/BlockChainBrowser/ChainTitle.vue'
import ChainSearch from '@/components/pc/BlockChainBrowser/ChainSearch.vue'
import ChainOverview from '@/components/pc/BlockChainBrowser/ChainOverview/index.vue'
import LatestBlock from '@/components/pc/BlockChainBrowser/LatestBlock/index.vue'
import LatestTx from '@/components/pc/BlockChainBrowser/LatestTx/index.vue'
import ccImg from '@/assets/images/blockChainBrowser/cc-image.png'
import blockChainBrowserMixin from '@/mixin/blockChainBrowser'

export default VueTypedMixins(blockChainBrowserMixin).extend({
  components: {
    ChainCard,
    ChainTitle,
    ChainSearch,
    ChainOverview,
    LatestBlock,
    LatestTx,
  },
  data() {
    return {
      ccImg,
    }
  },
})
