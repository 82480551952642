var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"last-block"},[_c('latest-container',{attrs:{"title":_vm.$t('lang.chainMsg.latestBlock'),"to":"/block"}},_vm._l((_vm.latestBlocks),function(item,i){return _c('div',{key:i,staticClass:"item flex px-7 py-4"},[_c('div',{staticClass:"\n          txNumberBox\n          text-footer-color text-sm\n          font-bold\n          text-center\n          flex-shrink-0\n          items-center\n          flex\n        "},[_c('div',{staticClass:"flex-grow overflow-hidden overflow-ellipsis"},[_c('router-link',{attrs:{"to":{
              path: '/blockDetail',
              query: {
                height: item.height,
              },
            }}},[_vm._v(" "+_vm._s(item.height)+" ")])],1)]),_c('div',{staticClass:"w-full ml-5"},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_c('span',{staticClass:"graytxt"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.hash"))+"： ")]),_c('router-link',{attrs:{"to":{
                path: '/blockDetail',
                query: {
                  height: item.height,
                },
              }}},[_c('span',{staticClass:"text-footer-color"},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.hash,9))+" ")])])],1),_c('div',[_c('span',{staticClass:"text-text-color"},[_vm._v(" "+_vm._s((item && item.txs && item.txs.length) || "")+" "+_vm._s(item && item.txs && item.txs.length === 1 ? _vm.$t("lang.block.tx") : _vm.$t("lang.block.txs"))+" ")])])]),_c('div',{staticClass:"flex justify-between items-center",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('span',{staticClass:"graytxt"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.packingAddress"))+"： ")]),_c('router-link',{attrs:{"to":{
                path: '/address',
                query: {
                  address: item.minerHash,
                },
              }}},[_c('span',{staticClass:"text-footer-color"},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.minerHash,9))+" ")])])],1),_c('div',{staticClass:"text-text-color",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.blockTime))+" ")])])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }