<template>
  <div class="flex justify-between">
    <chain-card-show-price
      :quoteChange="quoteChange"
      :price="price"
      :marketValue="marketValue"
    ></chain-card-show-price>
    <div>
      <chain-card-show-perf
        :txNumber="totalTx"
        :speed="speed"
        :tps="tps"
        :addressNumber="addressNumber"
      ></chain-card-show-perf>
      <chain-tx-number-chart style="margin-top: 30px;"></chain-tx-number-chart>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ChainCardShowPerf from './ChainCardShowPerf.vue'
import ChainCardShowPrice from './ChainCardShowPrice.vue'
import ChainTxNumberChart from './ChainTxNumberChart.vue'
export default Vue.extend({
  components: {
    ChainCardShowPrice,
    ChainCardShowPerf,
    ChainTxNumberChart,
  },
  props: {
    totalTx: Number,
    quoteChange: String,
    price: String,
    marketValue: Number,
    speed: Number,
    tps: Number,
    addressNumber: Number,
  },
})
</script>
