<template>
  <div class="latestblock shadow-shadow1">
    <div class="header flex items-center justify-between px-7">
      <div class="title">
        {{ title }}
      </div>
      <div class="seeMore cursor-pointer">
        <router-link :to="to">
          {{ $t('lang.viewMore') }}
        </router-link>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  props: {
    title: String,
    to: String,
  },
})
</script>

<style scoped>
.latestblock {
  width: 585px;
  height: 608px;
  background: #ffffff;
  border-radius: 4px;
}
.header {
  height: 80px;
}
.title {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #1f3470;
}
.seeMore {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2545cb;
}
</style>
