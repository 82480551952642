













































































import Vue, { PropType } from "vue";
import LatestContainer from "@/components/pc/BlockChainBrowser/LatestContainer.vue";
import { iAsset } from "@/types/trade";
export default Vue.extend({
  components: { LatestContainer },
  props: {
    latestTxs: Array as PropType<
      {
        hash: string;
        time_block: number;
        from: string;
        to: string;
        amount: number;
        assets: iAsset[];
        tx: {
          execer?: string;
        };
      }[]
    >,
  },
});
