var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('latest-container',{attrs:{"title":_vm.$t('lang.latestTx'),"to":"/tradeList"}},_vm._l((_vm.latestTxs),function(item,i){return _c('div',{key:i,staticClass:"item flex px-7 py-4 justify-between"},[_c('div',{staticClass:"left",staticStyle:{"line-height":"20px"}},[_c('div',[_c('span',{staticClass:"graytxt"},[_vm._v(_vm._s(_vm.$t("lang.trade.hash"))+"：")]),_c('span',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
                path: '/tradeHash',
                query: {
                  hash: item.hash,
                },
              }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.hash,8))+" ")])],1)]),_c('div',{staticClass:"graytxt",staticStyle:{"font-size":"12px","margin-top":"6px"}},[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.time_block))+" ")])]),_c('div',{staticClass:"middle",staticStyle:{"line-height":"20px"}},[_c('div',[_c('span',{staticClass:"graytxt"},[_vm._v(_vm._s(_vm.$t("lang.trade.sender"))+"：")]),_c('span',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
                path: '/address',
                query: {
                  address: item.from,
                },
              }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.from,8))+" ")])],1)]),_c('div',{staticClass:"graytxt",staticStyle:{"margin-top":"6px"}},[_c('span',{staticClass:"graytxt"},[_vm._v(_vm._s(_vm.$t("lang.trade.receiver"))+"：")]),_c('span',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
                path: '/address',
                query: {
                  address: item.to,
                },
              }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.to,8))+" ")])],1)])]),_c('div',{staticClass:"right text-right",staticStyle:{"line-height":"20px"}},[_c('div',{staticClass:"graytxt"},[_vm._v(" "+_vm._s(item.action_name == "miner" ? _vm.$t("lang.trade.pkgRewards") : _vm.$t("lang.trade.txAmount"))+" ")]),_c('div',{staticClass:"text-darkBlue",staticStyle:{"margin-top":"6px"}},[_vm._v(" "+_vm._s(_vm._f("filterTradeValue")(item))+" ")])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }