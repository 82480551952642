<template>
  <div class="_container shadow-shadow1 bg-white" style="padding: 30px;">
    <div class="flex justify-between">
      <div class="text-darkBlue text-lg font-bold">
        {{ $t('lang.chainPerf.txNumberOnTheChain') }}
      </div>
      <div class="flex items-center">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          @click="currentFilter = filter"
          class="text-text-color cursor-pointer ml-7"
          :class="{ ' text-footer-color': currentFilter === filter }"
        >
          {{ filter }}
        </div>
      </div>
    </div>
    <charts
      :scale="currentFilter"
      style="height: calc(220px - 28px);"
      :grid="{
        top: '20px',
        bottom: '25px',
        left: '50px',
        right: '20px',
      }"
    ></charts>
  </div>
</template>

<script>

import Charts from '@/components/charts.vue'
import Vue from 'vue'
export default Vue.extend({
  components: {
    Charts,
  },
  data() {
    return {
      filters: ['D1', 'W1', 'M1', 'Y1'],
      currentFilter: 'D1',
    }
  },
})
</script>

<style scoped>
._container {
  height: 280px;
  border-radius: 4px;
}
</style>
