





















import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      yc: require('@/assets/images/blockChainBrowser/home/yc.png'),
      cc: require('@/assets/images/blockChainBrowser/home/cc.png'),
    }
  },
})
